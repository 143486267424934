import React from "react"
import styled from "styled-components"
import { Text } from "../ziibo"

const StyledBadge = styled.div`
	padding: 0.4rem 0.8rem;
	background-color: ${(props) => props.theme.colors["primary"]["light"]};
	text-transform: uppercase;
	letter-spacing: 0.08rem;
`
const Badge = ({ children }) => {
	return (
		<StyledBadge>
			<Text fontSize={200} fontWeight="bold">
				{children}
			</Text>
		</StyledBadge>
	)
}

export { Badge }
