import React from "react"
// import { buildImageObj } from "../lib/helpers"
// import { imageUrlFor } from "../lib/image-url"
import { Text } from "../ziibo"

function AuthorList({ items, title }) {
	return (
		<div>
			<ul style={{ listStyle: "none" }}>
				{items.map(({ author, _key }) => {
					const authorName = author && author.name
					return (
						<li key={_key}>
							<div>
								<Text fontSize={200} mr={1.2}>
									{authorName || <em>Missing name</em>}
								</Text>
							</div>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default AuthorList
