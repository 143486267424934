import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../components/blog-post"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
	query BlogPostTemplateQuery($id: String!) {
		post: sanityPost(id: { eq: $id }) {
			id
			publishedAt
			categories {
				_id
				title
			}
			mainImage {
				asset {
					fluid(maxWidth: 1600) {
						...GatsbySanityImageFluid
					}
				}
				alt
				credit {
					creditLink
					creditText
				}
			}
			title
			slug {
				current
			}
			_rawExcerpt(resolveReferences: { maxDepth: 5 })
			_rawBody(resolveReferences: { maxDepth: 5 })
			authors {
				_key
				author {
					image {
						crop {
							_key
							_type
							top
							bottom
							left
							right
						}
						hotspot {
							_key
							_type
							x
							y
							height
							width
						}
						asset {
							_id
						}
					}
					name
				}
			}
		}
	}
`

const BlogPostTemplate = (props) => {
	const { data } = props
	const post = data && data.post
	return (
		<Layout>
			<SEO title={post.title} />
			{post && <BlogPost {...post} />}
		</Layout>
	)
}

export default BlogPostTemplate
