import React from "react"
import styled from "styled-components"
import { format } from "date-fns"
import PortableText from "./portableText"
import AuthorList from "./author-list"
import { Heading, Text } from "../ziibo"
import { Badge } from "./badge"
import Img from "gatsby-image"

const styledPostBody = {
	maxWidth: "96ch",
	width: "90%",
	margin: "0 auto",
	lineHeight: "1.8",
	fontSize: "1.8rem"
}

const Test = styled.div`
	& p {
		margin-bottom: 1.6rem;
	}

	& ol {
		margin-left: 1.6rem;
	}

	& a {
		text-decoration: underline;
		font-weight: 700;
		color: #e41f36;
	}
`

function BlogPost(props) {
	const { _rawBody, authors, categories, title, mainImage, publishedAt } = props
	console.log(publishedAt)
	return (
		<article style={{ margin: "24px auto" }}>
			<Heading style={{ textAlign: "center" }}>{title}</Heading>
			<aside style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "0 auto 36px auto", width: "90%" }}>
				{authors && <AuthorList items={authors} title="Authors" />}
				{publishedAt && (
					<>
						<Text mr={1.2}>|</Text>
						<Text fontSize={200}>{format(new Date(publishedAt), "MMMM do, yyyy")}</Text>
					</>
				)}
			</aside>
			{mainImage && mainImage.asset && (
				<div style={{ margin: "24px auto 0 auto", width: "90%" }}>
					<Img fluid={mainImage.asset.fluid} alt={mainImage.alt} style={{ margin: "0 auto", display: "block", width: "100%" }} />
				</div>
			)}
			{mainImage.credit && (
				<div style={{ margin: "8px auto 24px auto", width: "90%" }}>
					<a href={mainImage.credit.creditLink} style={{ fontSize: "1.2rem", textDecoration: "underline" }}>
						{mainImage.credit.creditText}
					</a>
				</div>
			)}
			<aside style={{ display: "flex", margin: "12px auto", width: "90%" }}>
				{categories && (
					<div>
						<ul style={{ listStyle: "none" }}>
							{categories.map((category) => (
								<li key={category._id}>
									<Badge>{category.title}</Badge>
								</li>
							))}
						</ul>
					</div>
				)}
			</aside>
			<React.Fragment>
				<div>
					<hr style={{ margin: "24px 0" }} />
					<Test style={styledPostBody}>{_rawBody && <PortableText blocks={_rawBody} />}</Test>
				</div>
			</React.Fragment>
		</article>
	)
}

export default BlogPost
